/**
 * Language const
 * Just follow the structure.
 */

const LANGUAGE = {
  "esp": {
    "navBar": {
      "home": "Inicio",
      "about": "Acerca de",
      "features": "Funcionalidades",
      "services": "Servicios",
      "contact": "Contacto"
    },
    "home": {
      "mainTitle": "LA EXPERIENCIA",
      "explore": "EXPLORA AHORA",
      "leftText": "BENEFICIO",
      "rightText": "SEGURIDAD"
    },
    "about": {
      "title": "Admin Web",
      "roundOne": "APP",
      "roundTwo": "WEB",
      "roundTree": "NUBE",
      "subTitle": "ADMINISTRA TUS HOGARES",
      "bulletOne": "Crear Hogares",
      "bulletTwo": "Crear Inquilinos",
      "bulletTree": "Almacenar Datos"
    },
    "Features": {
      "title": "Beneficios & Funciones",
      "buttons": {
        "buttonOne": "Equis"
      },
      "subTitle": "Vive la experiencia",
      "content": "Houselive es un software residencial totalmente integrado para dispositivos móviles, que brinda soluciones y servicios, necesarios para administrar y organizar los procesos internos en condominios y apartamentos. El sistema cuenta con un diseño amigable,  permite una mejor interacción entre la administración y sus inquilinos, ofreciendo valor a su estilo de vida. La herramienta permite a los  usuarios mantener sus datos administrativos en la nube, permitiendo un fácil acceso desde cualquier punto y dispositivo para controlar o solucionar sus problemas.",
    },
    "Services": {
      "title": "Administra tus inquilinos",
      "web": {
        "bulletOne": "Interactivo",
        "bulletTwo": "Multiplataforma",
        "bulletTree": "Rápida interacción"
      },
      "app": {
        "bulletOne": "Interactivo",
        "bulletTwo": "Multiplataforma",
        "bulletTree": "Rápida interacción"
      },
      "selected":"Seleccionado",
      "textOne": "Obten",
      "textTwo": "CONTROL EN",
      "textThree": "cualquier Sitio",
    },
    "Contact": {
      "page": "Contacto",
      "title": "Cuéntanos de tu proyecto",
      "bullets": {
        "one": "Número de residencias",
        "two": "Apartamento o condominio",
        "three": "Proyecto nuevo o existente",
        "four": "Región & País"
      },
      "Form": {
        "name": "NOMBRE",
        "phone": "TELÉFONO",
        "job": "PUESTO DE TRABAJO",
        "company": "COMPAÑÍA",
        "email": "CORREO ELECTRÓNICO",
        "message": "MENSAJE",
        "button":"ENVIAR",
      },
      "Footer": {
        "textOne": "HouseLive Inc.",
        "textTwo": "Notificaciones",
        "textThree": "Seguridad",
        "textFour": "Confianza",
        "textFive": "Encuentranos en:"
      },
    }
  },
  "eng": {
    "navBar": {
      "home": "Home",
      "about": "About",
      "features": "Features",
      "services": "Services",
      "contact": "Contact"
    },
    "home": {
      "mainTitle": "FIRST LOOK AT",
      "explore": "EXPLORE NOW",
      "leftText": "BENEFITS",
      "rightText": "CONTROL"
    },
    "about": {
      "title": "Admin Web",
      "roundOne": "APP",
      "roundTwo": "WEB",
      "roundTree": "CLOUD",
      "subTitle": "MANAGE YOUR PROJECT",
      "bulletOne": "Create Homes",
      "bulletTwo": "Create Tenants",
      "bulletTree": "Save Data"
    },
    "Features": {
      "title": "Benefits and Features",
      "subTitle": "Live the experience",
      "content": "Houselive is a fully integrated residential software for mobile devices. It provides solutions and services that help to manage and organize the internal processes apartments and residences. The system has a user friendly design that creates a better experience between the administration and its tenants, offering value to their lifestyle. The tool allows users to manage their data and keep it synched using a cloud based service, allowing easy access from any location and with any device, to control or solve their problems.",
    },
    "Services": {
      "title": "Administra tus inquilinos",
      "web": {
        "bulletOne": "Interactivo",
        "bulletTwo": "Multiplataforma",
        "bulletTree": "Rápida interacción"
      },
      "app": {
        "bulletOne": "Interactivo",
        "bulletTwo": "Multiplataforma",
        "bulletTree": "Rápida interacción"
      },
      "selected": "Selected",
      "textOne": "Cloud",
      "textTwo": "BASED",
      "textThree": "Service",
    },
    "Contact": {
      "page": "Contact",
      "title": "Let's discuss your project",
      "bullets": {
        "one": "Number of residences",
        "two": "New or existing project",
        "three": "Apartment or residence",
        "four": "Region & Country",
      },
      "Form": {
        "name": "YOUR NAME",
        "phone": "PHONE",
        "job": "JOB TITLE",
        "company": "YOUR COMPANY",
        "email": "EMAIL ADDRESS",
        "message": "MESSAGE",
        "button":"SEND",
      },
      "Footer": {
        "textOne": "HouseLive Inc.",
        "textTwo": "Notifications",
        "textThree": "Security",
        "textFour": "Trust",
        "textFive": "Follow us:"
      },
    }
  },
}

export { LANGUAGE }