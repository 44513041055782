import React from 'react'
import styles from './footer.module.css'

import instagram from '../../assets/icons/instagram_logo.png';
import facebook from '../../assets/icons/fb_logo.png';
import linkedin from '../../assets/icons/linkedin_logo.png';

export default function Footer({...props}) {
  return (
    <div className={styles.fullVHDiv}>
      <div className='row no-gutters'>
        <div className='col-lg-5 col-md-5 col-12'>
          <div className={styles.textContainer}>
            <p>{props.language.Contact.Footer.textOne}</p>
            <p>{props.language.Contact.Footer.textTwo}</p>
            <p>{props.language.Contact.Footer.textThree}</p>
          </div>
        </div>
        <div className='col-lg-3 col-md-3 col-12'>
          <div className={styles.textContainer}>
            <p>{props.language.Contact.Footer.textFour}</p>
            <p>{props.language.Contact.Footer.textFive}</p>
          </div>
        </div>
        <div className='col-lg-4 col-md-4 col-12'>
          <div className={styles.iconContainer}>
            <a href="https://www.instagram.com/houselive.app/" target="_blank" rel="noopener noreferrer">
              <img src={instagram} alt='instagram'/>
            </a>
            <a href="https://www.facebook.com/houseliveapp/" target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt='facebook'/>
            </a>
            <a href="https://www.linkedin.com/company/houselive/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
              <img src={linkedin} alt='linkedin'/>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
