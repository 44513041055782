import React from 'react'
import styles from './logos.module.css'

export default function Logos({...props}) {
  return (
    <>
      
    </>
  )
}
