import React from 'react'

import about from '../../assets/img/admin_slide_1.jpg'
import inquilinoImg from '../../assets/img/crear_inquilino.png'
import dataImg from '../../assets/img/data.png'
import hogaresImg from '../../assets/img/crear_horares.png'

import styles from './about.module.css'

export default function About({ ...props }) {
  return (
    <div className={styles.fullVHDiv}>
      <div className="row" name="about">
        <div className="col-10 offset-1 col-md-5 offset-md-1">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className={styles.title}>
                <p>{props.language.about.title}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={styles.aboutImage}>
                <img src={about} alt="demo" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-10 offset-1 col-md-5 offset-md-1">
          <div className="row no-gutters">
            <div className="offset-2 offset-lg-0 offset-md-0 col-3 col-md-2">
              <div className={styles.roundTitle}>
                <p>{props.language.about.roundOne}</p>
              </div>
            </div>
            <div className="col-3 col-md-2">
              <div className={styles.roundTitle}>
                <p>{props.language.about.roundTwo}</p>
              </div>
            </div>
            <div className="col-3 col-md-2">
              <div className={styles.roundTitle}>
                <p>{props.language.about.roundTree}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="offset-1 offset-lg-0 offset-md-0 col-10">
              <div className={styles.subTitle}>
                <p>{props.language.about.subTitle}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-lg-3 col-md-3">
              <div className="row">
                <div className="col-12">
                  <div className={styles.imgContainer}>
                    <img src={hogaresImg} alt="chat" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className={styles.bulletText}>
                    <p>{props.language.about.bulletOne}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-lg-3 col-md-3">
              <div className="row">
                <div className="col-12">
                  <div className={styles.imgContainer}>
                    <img src={inquilinoImg} alt="chat" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className={styles.bulletText}>
                    <p>{props.language.about.bulletTwo}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 col-lg-3 col-md-3">
              <div className="row">
                <div className="col-12">
                  <div className={styles.imgContainer}>
                    <img src={dataImg} alt="chat" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className={styles.bulletText}>
                    <p>{props.language.about.bulletTree}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
