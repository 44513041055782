import React from 'react'
import styles from './contact.module.css'
import { Link } from 'react-scroll'

import logo from '../../assets/icons/isotipo-min.png';
import arrow from '../../assets/icons/down_icon.png';
import instagram from '../../assets/icons/instagram_logo.png';
import facebook from '../../assets/icons/fb_logo.png';

export default function Contact({...props}) {
  return (
    <div className={styles.fullVHDiv}>
      <div className={`row ${styles.background}`} name='contact'>
        <div className='col-lg-6 col-md-6 col-12'>
          <div className={styles.container}>
            <div className={styles.iconsContainer}>
              <div className={styles.iconLogoContainer}>
                <img className={styles.logo} src={logo} alt="logo"/>
              </div>
              {/* <div className={styles.iconArrowContainer}>
                <img className={styles.arrow} src={arrow} alt="arrow"/>
              </div> */}
            </div>
            <div className={styles.textContainer}>
              <div className='row no-gutters'>
                <div className='col-12'>
                  <div className='row no-gutters'>
                    <div className='col-12'>
                      <p className={styles.contact}>{props.language.Contact.page}</p>
                      <div className={styles.detail}/>
                      <p className={styles.title}>{props.language.Contact.title}</p>
                    </div>
                  </div>
                  <div className='row no-gutters'>
                    <div className='col-lg-12 col-md-12 col-6'>
                      <div className={styles.vinetas}>
                        <div className={styles.vineta}>
                          <p className={styles.point}>• </p>
                          <p>{props.language.Contact.bullets.one}</p>
                        </div>
                        <div className={styles.vineta}>
                          <p className={styles.point}>• </p>
                          <p>{props.language.Contact.bullets.two}</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-6'>
                      <div className={styles.vinetas}>
                        <div className={styles.vineta}>
                          <p className={styles.point}>• </p>
                          <p>{props.language.Contact.bullets.three}</p>
                        </div>
                        <div className={styles.vineta}>
                          <p className={styles.point}>• </p>
                          <p>{props.language.Contact.bullets.four}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-5 col-md-5 col-12'>
          <div className={styles.blur}/>
          <div className='row no-gutters'>
            <div className='col-6'>
              <div className={styles.inputContainer}>
                <input
                  name='name'
                  className={`${styles.inputTop} ${styles.input}`}
                  placeholder={props.language.Contact.Form.name}
                  value={props.name}
                  onChange={props.onChange}
                />
                <input
                  name='job'
                  className={styles.input}
                  placeholder={props.language.Contact.Form.job}
                  value={props.job}
                  onChange={props.onChange}
                />
              </div>
            </div>
            <div className='col-6'>
              <div className={styles.inputContainer}>
                  <input
                    name='phone'
                    className={`${styles.inputTop} ${styles.input}`}
                    placeholder={props.language.Contact.Form.phone}
                    value={props.phone}
                    onChange={props.onChange}
                  />
                  <input
                    name='company_name'
                    className={styles.input}
                    placeholder={props.language.Contact.Form.company}
                    value={props.company_name}
                    onChange={props.onChange}
                  />
                </div>
            </div>
          </div>
          <div className='row no-gutters'>
            <div className='col-12'>
              <div className={styles.inputContainer}>
                <input
                  name='email'
                  className={styles.input}
                  placeholder={props.language.Contact.Form.email}
                  value={props.email}
                  onChange={props.onChange}
                />
              </div>
            </div>
            <div className='col-12'>
              <div className={styles.inputContainer}>
                <textarea
                  name='message'
                  className={styles.textarea}
                  placeholder={props.language.Contact.Form.message}
                  value={props.message}
                  onChange={props.onChange}
                  rows="3"
                />
              </div>
            </div>
            <div className='col-12'>
              <div className={styles.completedMesssage}>
                <p  hidden={!props.buttonClicked}>Se envió su mensaje con éxito</p>
                <div className={styles.check}  hidden={!props.buttonClicked}/>
              </div>
            </div>
            <div className='col-12'>
              <button
                className={styles.sendButton}
                type='submit'
                onClick={(e)=> {
                  props.onSubmit(e);
                  props.onClick();
                }}
              >
                {props.language.Contact.Form.button}
              </button>
            </div>
          </div>
        </div>
        <div className='col-lg-1 col-md-1 col-12 d-lg-block d-md-block d-none'>
          <div className={styles.iconInstagramContainer}>
            {/* <img className={styles.instagram} src={instagram} alt="instagram"/> */}
            <Link
              to="home"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              <img  className={styles.arrow} src={arrow} alt="Up" />
            </Link>
            {/* <img className={styles.arrow} src={arrow} alt="arrow"/> */}
          </div>
          {/* <div className={styles.iconFacebookContainer}>
            <img className={styles.facebook} src={facebook} alt="facebook"/>
          </div> */}
        </div>
      </div>
    </div>  
    
  )
}
