import React from 'react'
import { Link } from 'react-scroll';

import logo from '../../assets/icons/logo_min.png'
import respIcon from '../../assets/icons/tab.png'

import styles from './nav.module.css'

export default function NavBar({ ...props }) {
  return (
    <div className={`row ${styles.navRow}`}>
      <div className="col-6 col-md-2">
        <div className={styles.logoImg}>
          <img src={logo} alt="Logo" />
        </div>
      </div>
      <div className="col-md-6 offset-md-1 d-none d-md-block">
        <div className="row">
          <div className="col">
            <Link
              to="home"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              <div className={styles.navItem}>
                <p>{props.language.navBar.home}</p>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link
              to="about"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              <div className={styles.navItem}>
                <p>{props.language.navBar.about}</p>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link
              to="features"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              <div className={styles.navItem}>
                <p>{props.language.navBar.features}</p>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link
              to="services"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              <div className={styles.navItem}>
                <p>{props.language.navBar.services}</p>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link
              to="contact"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              <div className={styles.navItem}>
                <p>{props.language.navBar.contact}</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-md-1 offset-md-1 d-none d-md-block">
        <div className="row">
          <div className="col">
            <div className={`${props.selectedLanguage === 'eng' ? styles.navLanguageItem : styles.hide}`}>
              <button
                onClick={() => { props.onSelect("esp") }}
              >
                ESP
              </button>
            </div>
          </div>
          <div className="col">
            <div className={`${props.selectedLanguage === 'esp' ? styles.navLanguageItem : styles.hide}`}>
              <button
                onClick={() => { props.onSelect("eng") }}
              >
                ENG
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-6 d-block d-md-none">
        <div
          className={styles.downIcon}
          onClick={() => { props.dropdownDisplay() }}
        >
          <img src={respIcon} alt="Down Icon" />
        </div>
      </div>
      <div
        hidden={!props.dropdownVisible}
        className={styles.dropdownContainer}
      >
        <Link
          className={styles.itemOne}
          to="home"
          activeClass="active"
          spy={true}
          smooth={true}
          duration={500}
        >
          <div className={styles.navItem}>
            <p>
              {props.language.navBar.home}
            </p>
          </div>
        </Link>
        <Link
          className={styles.itemTwo}
          to="about"
          activeClass="active"
          spy={true}
          smooth={true}
          duration={500}
        >
          <div className={styles.navItem}>
            <p>
              {props.language.navBar.about}
            </p>
          </div>
        </Link>
        <Link
          className={styles.itemThree}
          to="features"
          activeClass="active"
          spy={true}
          smooth={true}
          duration={500}
        >
          <div className={styles.navItem}>
            <p>
              {props.language.navBar.features}
            </p>
          </div>
        </Link>
        <Link
          className={styles.itemFour}
          to="services"
          activeClass="active"
          spy={true}
          smooth={true}
          duration={500}
        >
          <div className={styles.navItem}>
            <p>
              {props.language.navBar.services}
            </p>
          </div>
        </Link>
        <Link
          className={styles.itemFive}
          to="contact"
          activeClass="active"
          spy={true}
          smooth={true}
          duration={500}
        >
          <div className={styles.navItem}>
            <p>
              {props.language.navBar.contact}
            </p>
          </div>
        </Link>
        <div className={`${props.selectedLanguage === 'eng' ? styles.navLanguageItem : styles.hide}`}>
          <button
            onClick={() => { props.onSelect("esp") }}
          >
            ESP
          </button>
        </div>
        <div className={`${props.selectedLanguage === 'esp' ? styles.navLanguageItem : styles.hide}`}>
          <button
            onClick={() => { props.onSelect("eng") }}
          >
            ENG
          </button>
        </div>
      </div>
    </div>
  )
}
