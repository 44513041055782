const INITIAL_STATE = {
  /** Nav bar values */
  language: "esp",
  dropdownVisible: false,

  /** Feature values */
  selectedFeature: 1,

  /** Services values */
  selectedService: "Admin",

  /** Contact form values */
  buttonClicked: false,
  name: "",
  phone: "",
  job: "",
  company_name: "",
  email: "",
  message: "",
};

export { INITIAL_STATE };