import React from 'react'
import { Link } from 'react-scroll';

import NavBar from '../NavBar'

import logo from '../../assets/icons/logo_grande.png'
import fbIcon from '../../assets/icons/fb_logo.png'
import igIcon from '../../assets/icons/instagram_logo.png'
import inIcon from '../../assets/icons/linkedin_logo.png'
import line from '../../assets/icons/line_home.png'
import down from '../../assets/icons/down_icon.png'

import styles from './home.module.css'

export default function Home({ ...props }) {
  return (
    <div className={styles.fullVHDiv}>
      <div className={`row ${styles.background}`} name="home">
        <div className="col-12">
          <NavBar
            selectedLanguage={props.selectedLanguage}
            language={props.language}
            onSelect={props.onSelectLanguage}
            dropdownDisplay={props.dropdownDisplay}
            dropdownVisible={props.dropdownVisible}
          />
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              {/* <div className="row">
                <div className="col-12">
                  <div className={styles.title}>
                    <p>{props.language.home.mainTitle}</p>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-1 d-lg-block d-md-block d-none">
                  <div className={styles.mediaContainer}>
                    <div className="row">
                      <div className="col-12">
                        <div className={styles.mediaButtons}>
                          <a href="https://www.facebook.com/houseliveapp/" target="_blank" rel="noopener noreferrer">
                            <img src={fbIcon} alt="FB" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className={styles.mediaButtons}>
                          <a href="https://www.instagram.com/houselive.app/" target="_blank" rel="noopener noreferrer">
                            <img src={igIcon} alt="IG" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className={styles.mediaButtons}>
                          <a href="https://www.linkedin.com/company/houselive/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                            <img src={inIcon} alt="in" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="offset-1 offset-md-0 offset-lg-0 col-10">
                  <div className={styles.title}>
                    <p>{props.language.home.mainTitle}</p>
                  </div>
                  <div className={styles.mainLogo}>
                    <img src={logo} alt="logo" />
                  </div>
                </div>
                <div className='offset-1 col-10 d-block d-md-none d-lg-none'>
                  <div className={styles.mediaContainer}>
                    <div className={styles.mediaButtons}>
                      <a href="https://www.instagram.com/houselive.app/" target="_blank" rel="noopener noreferrer">
                        <img src={igIcon} alt="IG" />
                      </a>
                    </div>
                    <div className={styles.mediaButtons}>
                      <a href="https://www.facebook.com/houseliveapp/" target="_blank" rel="noopener noreferrer">
                        <img src={fbIcon} alt="FB" />
                      </a>
                    </div>
                    <div className={styles.mediaButtons}>
                      <a href="https://www.linkedin.com/company/houselive/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                        <img src={inIcon} alt="in" />
                      </a>
                    </div>

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 d-none d-md-block">
                  <div className={styles.leftText}>
                    <p>{props.language.home.leftText}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-12">
                      <div className={styles.exploreLine}>
                        <img src={line} alt="Line" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className={styles.exploreText}>
                        <p>{props.language.home.explore}</p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className={styles.exploreButton}>
                        <Link
                          to="about"
                          activeClass="active"
                          spy={true}
                          smooth={true}
                          duration={500}
                        >
                          <img src={down} alt="Down" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 d-md-block d-none">
                  <div className={styles.rightText}>
                    <p>{props.language.home.rightText}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
