import React from 'react'
import styles from './features.module.css'

export default function Features({...props}) {
  return (
    <div className={styles.fullVHDiv}>
      <div className='row' name='features'>
        <div className='offset-lg-1 offset-md-1 col-lg-10 col-md-10 col-12'>
          <div className={styles.title}>
            <p>{props.language.Features.title}</p>
          </div>
          <div className={styles.background}>
            <div className='row'>
              <div className='col-12'>
                <div className={styles.image}/>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                {/* <div className={styles.texts}>
                  <p>Texto simulado</p>
                  <p>Texto simulado</p>
                </div> */}
                <div className={styles.mainTitle}>
                  <p>{props.language.Features.subTitle}</p>
                </div>
                <div className={styles.paragraph}>
                  <p>{props.language.Features.content}</p>
                </div>
              </div>
            </div>
            <div className='row no-gutters'>
              <div className='col-lg-6 col-md-6 col-12'>
                <div className='row no-gutters'>
                  <div className='col-12' style={{color:'white'}}>
                    <div 
                      className=
                        {`
                          ${styles.amenities}
                          ${props.selectedLanguage==='esp' ? styles.amenitiesEsp: styles.amenitiesEng}
                        `}>
                    </div>
                  </div>
                </div>
                <div className='row no-gutters'>
                  <div className='col-6' style={{color:'white'}}>
                    <div 
                      className={`
                        ${styles.alerts}
                        ${props.selectedLanguage==='esp' ? styles.alertsEsp: styles.alertsEng}
                      `}
                    />
                  </div>
                  <div className='col-6' style={{color:'white'}}>
                    <div 
                      className={`
                        ${styles.messages}
                        ${props.selectedLanguage==='esp' ? styles.messagesEsp: styles.messagesEng}
                      `}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-12'>
                <div className='row no-gutters'>
                  <div className='col-6' style={{color:'white'}}>
                    <div 
                      className={`
                        ${styles.payment}
                        ${props.selectedLanguage==='esp' ? styles.paymentEsp: styles.paymentEng}
                      `}
                    />
                  </div>
                  <div className='col-6'>
                    <div className='row no-gutters'>
                      <div className='col-12'style={{color:'white'}}>
                        <div 
                          className={`
                            ${styles.visits}
                            ${props.selectedLanguage==='esp' ? styles.visitsEsp: styles.visitsEng}
                          `}
                        />
                      </div>
                    </div>
                    <div className='row no-gutters'>
                      <div className='col-12' style={{color:'white'}}>
                        <div 
                          className={`
                            ${styles.work}
                            ${props.selectedLanguage==='esp' ? styles.workEsp: styles.workEng}
                          `}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
