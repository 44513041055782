import React from 'react'
import axios from 'axios'

import { Helmet } from 'react-helmet'

import { INITIAL_STATE } from '../../variables/initial_state'
import { LANGUAGE } from '../../variables/language'

import Home from '../Home'
import About from '../About'
import Features from '../Features'
import Services from '../Services'
import Logos from '../Logos'
import Contact from '../Contact'
import Footer from '../Footer'

import styles from './app.module.css'

const mailgun = require("mailgun-js");
class App extends React.Component {
  _isMounted = false;
  
  constructor() {
    super();
    this.state = {...INITIAL_STATE}
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleLanguage = (value) => {
    //console.log(value)
    this._isMounted && this.setState({ language: value });
  }

  dropdownDisplay = () => {
    this._isMounted && this.setState({ dropdownVisible: !this.state.dropdownVisible })
  }

  onClick = () => {
    this.setState({ buttonClicked: true });
    setTimeout(() => {
      this.setState({ buttonClicked: false }); 
    }, 2000);
    this.setState({name:'', phone:'', job:'', company_name:'',email:'',message:''})
  };

  onSubmit = (event) => {
    const {
      name,
      phone,
      job,
      company_name,
      email,
      message
    } = this.state

    event.preventDefault();

    const DOMAIN = 'mg.houseliveapp.com';
    const mg = mailgun({apiKey: 'affa832011b5aed81ee0f1fa44c42960-074fa10c-26403bd5', domain: DOMAIN});
    const data = {
      from: 'Info <info@houseliveapp.com>',
      to: 'jgarcia@houseliveapp.com, zquintana@houseliveapp.com,rpalarea@houseliveapp.com, dreyes@houseliveapp.com',
      subject: 'Informacion desde pagina web',
      text: 'Cliente: '+name+'\nTeléfono: '+phone+'\nTrabajo: '+job+'\nEmpresa: '+company_name+'\nEmail: '+email+'\nMensaje:\n'+message
    };
    mg.messages().send(data, function (error, body) {
        if(error) console.log(error) 
        else console.log(body); 
      });
    }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  serviceChange = service =>{
    this.setState({['selectedService']: service})
  };

  render() {
    return(
      <div className={`container-fluid ${styles.background}`}>
        <Helmet>
          <title>Houselive App</title>
          <meta 
            name='description' 
            content='Administrador de condominios, apartamentos y residencias'
          />
          <meta 
            name='keywords'
            content='Administradores para condominios, Administradores para apartamentos, Administrador para residencia, Administrador de propiedades, Programas para administrar residencias, Programa para control de inquilinos, Servicios para apartamentos, Programas para administrar apartamentos, App Residencial, Bienes Raices, Inmobiliaria, Residencial, Condominios, Apartementos, Propiedades, Despartamentos, Administrador, Hogar, Tecnología, Sistema, Vivienda'
          />
        </Helmet>
        <Home
          selectedLanguage={this.state.language}
          selected={this.state.selectedTitle}
          language={LANGUAGE[this.state.language]}
          onSelectLanguage={this.handleLanguage}
          dropdownDisplay={this.dropdownDisplay}
          dropdownVisible={this.state.dropdownVisible}
        />
        <About
          language={LANGUAGE[this.state.language]}
        />
        <Features
          language={LANGUAGE[this.state.language]}
          selectedLanguage={this.state.language}
        />
        <Services
          language={LANGUAGE[this.state.language]}
          selectedService={this.state.selectedService}
          serviceChange={this.serviceChange}
          selectedLanguage={this.state.language}
        />
        <Logos
          language={LANGUAGE[this.state.language]}
        />
        <Contact
          {...this.state}
          language={LANGUAGE[this.state.language]}
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          onClick={this.onClick}
          buttonClicked={this.state.buttonClicked}
        />
        <Footer
          language={LANGUAGE[this.state.language]}
        />
      </div>
    )
  }
}

export default App