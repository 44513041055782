import React from 'react'
import styles from './services.module.css'

export default function Services({...props}) {
  return (
    <div className={styles.fullVHDiv}>
      <div className='row no-gutters' style={{height:'100%'}} name='services'>
        <div className='col-lg-6 col-md-6 col-12'>
          <div
            className={`
              ${props.selectedLanguage === 'esp' ? (
                props.selectedService === 'Admin' ? styles.mainAdminImageEsp: styles.mainAppImageEsp) : (
                props.selectedService === 'Admin' ? styles.mainAdminImageEng: styles.mainAppImageEng)}
            `}>
          </div>
        </div>
        <div className='col-lg-5 offset-lg-1 offset-md-1 col-md-5 col-12'>
          <div className='row no-gutters'>
            <div className='offset-3 col-6 d-none d-md-block d-lg-block'>
              <div className={styles.logo}/>
            </div>
          </div>
          <div className='row no-gutters'>
            <div className='col-6'>
              <div className={`${props.selectedService === 'Admin' ? styles.selectedText: styles.hide}`}>
                <p>{props.language.Services.selected}</p>
              </div>
              <div className={styles.adminImage} onClick={() => props.serviceChange('Admin')}>
              </div>
            </div>
            <div className='col-6'>
              <div className={`${props.selectedService === 'App'? styles.selectedText: styles.hide}`}>
                <p>{props.language.Services.selected}</p>
              </div>
              <div className={styles.appImage} onClick={() => props.serviceChange('App')}>

              </div>
            </div>
          </div>
          {/* <div className='row no-gutters'>
            <div className='col-12'>
              <div className={styles.textsContainer}>
                <p>{props.language.Services.textOne}</p>
                <p>{props.language.Services.textTwo}</p>
                <p>{props.language.Services.textThree}</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
